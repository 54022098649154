<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">资料管理中心</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 15px">
            <div title="角色名称" class="searchboxItem ci-full">
              <span class="itemLabel">角色名称:</span>
              <el-input
                v-model="roleName"
                clearable
                placeholder="请输入角色名称"
                size="small"
              ></el-input>
            </div>
            <div title="角色类型" class="searchboxItem ci-full">
              <span class="itemLabel">角色类型:</span>
              <el-select
                clearable
                size="small"
                v-model="roleTypeId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in roleType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div title="是否上传" class="searchboxItem ci-full">
              <span class="itemLabel">是否上传:</span>
              <el-select
                clearable
                size="small"
                v-model="uploadState"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in uploadStateSelectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df" style="padding-left: 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="角色名称"
                align="left"
                show-overflow-tooltip
                prop="roleName"
                min-width="200"
              />

              <el-table-column
                label="角色类型"
                align="center"
                show-overflow-tooltip
                prop="roleType"
                width="200"
              />
              <el-table-column
                label="是否上传"
                align="center"
                show-overflow-tooltip
                prop="uploadState"
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.uploadState == "0" ? "否" : "是" }}
                </template>
              </el-table-column>
              <el-table-column
                label="最后一次上传时间"
                align="center"
                show-overflow-tooltip
                prop="lastTime"
                min-width="200"
              />

              <el-table-column
                label="文件数量"
                align="center"
                show-overflow-tooltip
                prop="fileNum"
                min-width="150"
              />
              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="uploadFile(scope.row.roleId)"
                    >上传材料</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="上传材料"
      :visible.sync="dialogVisible"
      width="55%"
      top="1%"
      :center="true"
      v-if="dialogVisible"
      @close="uploadFileClose"
    >
      <div class="ovy-a" style="height: 550px">
        <el-button
          size="small"
          type="primary"
          class="bgc-bv"
          @click="uploadFileAdd('add')"
          style="margin-bottom: 10px"
          >新增</el-button
        >
        <el-table
          ref="multipleTable"
          :data="uploadFileList"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" fixed />
          <el-table-column
            label="文件名称"
            align="left"
            show-overflow-tooltip
            prop="fileName"
            min-width="200"
          />

          <el-table-column
            label="上传人"
            align="center"
            show-overflow-tooltip
            prop="createName"
          />
          <el-table-column
            label="上传时间"
            align="center"
            show-overflow-tooltip
            prop="createTime"
            min-width="100"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | momentDate }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <div slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="uploadFileAdd('edit', scope.row)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="removeFile(scope.row.fileId)"
                >删除</el-button
              >
            </div>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </el-dialog>
    <!-- 上传附件 - loading -->
    <el-dialog
      title="上传附件"
      :visible.sync="uploadFileDialog"
      width="35%"
      class="toVoidLoding"
    >
      <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
        <el-form-item label="文件名称" prop="fileName">
          <el-input
            v-model="ruleForm.fileName"
            type="text"
            size="small"
            placeholder="请输入文件名称"
          />
        </el-form-item>
      </el-form>
      <div style="margin: 10 0 10px">
        <el-upload
          class="upload-demo upload-btn upload-btndrag"
          :action="actionUrl"
          :on-error="handleError"
          :on-success="handleSuccess"
          :on-change="uploadChange"
          :show-file-list="false"
          :auto-upload="false"
          drag
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <div
          v-if="ruleForm.fileUrl"
          style="margin-top: 10px; margin-left: 10px"
        >
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span style="color: #409eff">
              {{ ruleForm.fileName }}
              <a
                @click="lookExl(ruleForm)"
                style="color: #409eff; margin: 0 15px"
                >预览</a
              >
            </span>
          </div>
        </div>
        <div v-else>
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="canCleForm('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoading"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div
          v-else-if="
            fileType == 'png' || fileType == 'jpg' || fileType == 'jpeg'
          "
        >
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
        <div v-else>
          <video
            :src="ImgSrc"
            autoplay
            controls
            width="100%"
            height="500px"
          ></video>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "activeConfigurationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      roleName: "", //角色名称
      roleTypeId: "", //角色类型ID
      uploadState: "", //赠送内容
      roleType: [], //角色类型
      //是否上传
      uploadStateSelectList: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      dialogVisible: false,
      ruleForm: {
        fileName: "", //文件名称
      },
      uploadFileDialog: false, //上传附件弹窗
      uploadFileList: [],
      previewLoading: false, //预览
      fileType: "",
      ImgSrc: "",
    };
  },
  watch: {},
  created() {
    this.getRole();
  },
  computed: {},
  mounted() {},
  methods: {
    // 初始化获取评论列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.roleName) {
        params.roleName = this.roleName;
      }
      if (this.roleTypeId) {
        params.roleTypeId = this.roleTypeId;
      }
      if (this.uploadState) {
        params.uploadState = this.uploadState;
      }
      this.doFetch({
        url: "/biz/roleFile/record/pageList",
        params,
        pageNum,
      });
    },
    getRole() {
      this.$post("/sys/per/role/basal")
        .then((res) => {
          this.roleType = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    //上传附件
    uploadFile(roleId) {
      this.dialogVisible = true;
      this.roleId = roleId;
      this.getFileList(roleId);
    },
    getFileList(roleId) {
      this.$post("/biz/roleFile/pageList", { roleId })
        .then((ret) => {
          this.uploadFileList = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },

    //上传材料 - 新增 || 编辑
    uploadFileAdd(stu, row = {}) {
      this.uploadFileDialog = true;
      this.ruleForm = {
        ...row
      } 
      this.fileId = row.fileId;
      this.stu = stu;
    },
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
        const isXLSX = extension === "PDF";
        const isxlsx = extension === "pdf";
        const jpg = extension === "jpg";
        const png = extension === "png";
        const mp4 = extension === "mp4";
      //   // const isXLS = extension === "XLS";
      //   // const isxls = extension === "xls";
        if (!isXLSX && !isxlsx && !jpg && !png&& !mp4 ) {
          this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png或.mp4的文件");
          return;
        }

      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData).then((result) => {
        this.ruleForm = {
          fileName: file.name,
          fileKey: result.data.fileKey,
          fileUrl: result.data.fileURL,
          fileType: extension,
        };
      });
    },
    //确认
    getSure() {
      let parameter = {};
      if (this.fileId) {
        parameter = {
          fileName: this.ruleForm.fileName,
          fileId: this.fileId,
        };
      } else {
        parameter = {
          roleId: this.roleId,
          fileName: this.ruleForm.fileName,
          fileKey: this.ruleForm.fileKey,
          fileType: this.ruleForm.fileType,
        };
      }

      this.$post(
        this.fileId ? "/biz/roleFile/modify" : "/biz/roleFile/insert",
        parameter
      ).then((res) => {
        if (res.status == 0) {
          this.$message({
            message: "新增成功",
            type: "success",
          });
          this.canCleForm();
          this.getFileList(this.roleId);
        }
      });
    },
    canCleForm() {
      this.uploadFileDialog = false;
      this.ruleForm = {};
    },
    //删除附件
    removeFile(fileId) {
      this.$post("/biz/roleFile/delete", { fileId }).then((ret) => {
        if (ret.status == 0) {
          this.$message({
            message: ret.message,
            type: "success",
          });
          this.getFileList(this.roleId);
        }
      });

      this.$forceUpdate();
    },
    lookExl(item) {
      console.log(item);
      this.previewLoading = true;
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = item.fileUrl;
      }
    },
    //关闭上传材料
    uploadFileClose() {
      this.dialogVisible = false;
      this.getData(-1);
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.tip {
  cursor: pointer;
  background: #f2f6fc;
}
/deep/.el-upload {
  border: none;
  height: 180px !important;
}
</style>